<div id="cookiesPopup" *ngIf="isShowCookiesPopUp">
  <div class="net_container">
    <div class="popup_center">
      <div class="popup">
        <div class="modal_body">
          <div class="accept_cookies_popup net_center">
            <div class="cookies_content">
              <img
                src="/assets/images/vertel-logo-white.svg"
                alt="logo"
                loading="lazy"
                width="300"
                height="89"
                class="lighthouse_img"
              />
              <p>
                This site uses cookies as described in our Privacy Policy.
                Please click the “Accept” button to continue to use our site if
                you agree to our use of cookies.
              </p>
              <button
                id="cookiesHide"
                class="btn_global"
                (click)="acceptCookies()"
              >
                Accept
              </button>
            </div>
            <div class="cookies_learn_more">
              <p>
                To learn more about our Privacy Policy, please
                <a routerLink="/privacy-policy"><strong>click here.</strong></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
