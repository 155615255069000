import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {Router} from '@angular/router';
import {
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  map,
  Subscription,
  switchMap,
} from 'rxjs';

import {ConfigService} from 'src/app/services/config/config.service';
import {
  BlogAttributes,
  Data,
  Pagination,
} from 'src/app/interfaces/blog.interface';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [
    './header.component.scss',
    './header-mobile.component.scss',
    './header-media.component.scss',
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() public collapse: EventEmitter<void> = new EventEmitter();
  @HostListener('window:scroll', ['$event'])
  public checkScroll() {
    this.isSticky = window.scrollY >= 100;
  }
  public isShowSearchBox = false;
  public isExpanded = false;
  public isSticky: boolean = false;
  public isMenuShow = false;
  public queryText = '';
  public urls: Data<BlogAttributes>[] = [];
  public pagination: Pagination | null = null;
  public form = new UntypedFormGroup({
    query: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(25),
    ]),
  });
  public readonly cdnPrefix: string = environment.cms;
  private subs = new Subscription();
  public selectedIndex = -1;
  public readonly menus: any = [
    {
      title: 'Services',
      link: '/services',
      children: [
        {
          category: 'Network Solutions',
          link: '/services/network-solutions',
          subMenus: [
            {
              title: 'SD-WAN',
              link: '/services/network-solutions/sd-wan',
            },
            {
              title: 'Business Wi-Fi Solutions',
              link: '/services/network-solutions/managed-wifi',
            },
            {
              title: 'Enterprise Ethernet',
              link: '/services/network-solutions/enterprise-ethernet',
            },
            {
              title: 'Fixed Wireless',
              link: '/services/network-solutions/fixed-wireless',
            },
            {
              title: 'Hybrid Networks',
              link: '/services/network-solutions/hybrid-networks',
            },
            {
              title: 'Network Security',
              link: '/services/network-solutions/network-security',
            },
          ],
        },
        {
          category: 'Critical Communications',
          link: '/services/critical-communications',
          subMenus: [
            // {
            //   title: 'In-Vehicle Network Solutions',
            //   link: '/services/critical-communications/in-vehicle-network-solutions',
            // },
            {
              title: 'Mission Critical Mobility Solution',
              link: '/services/critical-communications/mission-critical-mobility-solution',
            },
            {
              title: 'MCX-Mission Critical Services',
              link: '/services/critical-communications/mission-critical-services',
            },
            {
              title: 'Push to Talk over Cellular',
              link: '/services/critical-communications/poc',
            },
            {
              title: 'Virtual Training for Mission Critical Services',
              link: '/services/critical-communications/virtual-training',
            },
            {
              title: 'Managed Radio Services',
              link: '/services/critical-communications/managed-radio-services',
            },
            {
              title: 'Radio Dispatch Console',
              link: 'services/critical-communications/radio-dispatch-console',
            },
          ],
        },
        {
          category: 'Cloud Services',
          link: '/services/cloud-services',
          subMenus: [
            {
              title: 'Cloud Solutions',
              link: '/services/cloud-services/cloud-solutions',
            },
            {
              title: 'Back up as a Service',
              link: '/services/cloud-services/baas',
            },
            // {
            //   title: 'Unified Communications', commented as discussed by Gautam Jain
            //   link: '#',
            // },
          ],
        },
        {
          category: 'Software as a service',
          link: '/services/saas',
          subMenus: [
            {
              title: 'Wifi Analytics',
              link: '/services/saas/wi-fi-analytics',
            },
            {
              title: 'Digital Wayfinding and Asset Tracking',
              link: '/services/saas/digital-wayfinding',
            },
            {
              title: 'Unified Communications as a Service',
              link: '/services/saas/ucaas',
            },
          ],
        },
        {
          category: 'Security Solutions',
          link: '/services/security-solutions',
          subMenus: [
            {
              title: 'CCTV Services & Systems',
              link: '/services/security-solutions/cctv-surveillance',
            },
            {
              title: 'Surveillance Storage',
              link: '/services/security-solutions/surveillance-storage',
            },
            {
              title: 'Access Control',
              link: '/services/security-solutions/access-control',
            },
            {
              title: 'License Plate Recognition',
              link: '/services/security-solutions/license-plate-recognition',
            },
          ],
        },
        // {
        //   category: 'Mobile Radio Services',
        //   link: '/services/mobile-radio-services',
        //   subMenus: [
        //     {
        //       title: 'Managed Radio Services',
        //       link: '/services/mobile-radio-services/managed-radio-services',
        //     },
        //     {
        //       title: 'Push to talk over Cellular',
        //       link: '/services/mobile-radio-services/poc',
        //     },
        //     {
        //       title: 'Site Facilities',
        //       link: '/services/mobile-radio-services/site-facilities',
        //     },
        //     {
        //       title: 'Mission Critical PTT',
        //       link: '/services/mission-critical-ptt',
        //     },
        //   ],
        // },
      ],
    },
    {
      title: 'Industries',
      link: '/industries',
      children: [
        {
          category: 'Industries',
          subMenus: [
            {
              title: 'Government',
              link: '/industries/government',
            },
            {
              title: 'Enterprise',
              link: '/industries/enterprise',
            },
            {
              title: 'Utilities',
              link: '/industries/utilities',
            },
            {
              title: 'Healthcare Providers',
              link: '/msp-healthcare',
            },
            {
              title: ' Holiday Parks ',
              link: '/industries/holidayparks',
            },
            {
              title: 'Renewables',
              link: '/industries/renewables',
            },
            {
              title: 'Education',
              link: '/industries/education',
            },
          ],
        },
      ],
    },
    {
      title: 'News & Case Studies',
      link: '/resources',
    },
    {
      title: 'About us',
      link: '/about-us',
      children: [
        {
          category: 'About us',
          subMenus: [
            {
              title: 'Careers',
              link: '/about-us/careers',
            },
            {
              title: 'CSR',
              link: '/about-us/corporate-social-responsibility',
            },
            {
              title: 'Our Team',
              link: '/about-us/our-expert-ict-msp-team',
            },
          ],
        },
      ],
    },
    {
      title: 'Contact us',
      link: '/contact-us',
    },
  ];

  constructor(private configService: ConfigService, private router: Router) {}

  public ngOnInit(): void {
    this.subs.add(
      this.query.valueChanges
        .pipe(
          debounceTime(250),
          map((query: string) => (this.queryText = query.trim().toLowerCase())),
          distinctUntilChanged(),
          switchMap(query =>
            forkJoin([
              this.configService.getSearchResult(query, 5),
              this.configService.getLocalSearchResult(query, 5),
            ]),
          ),
        )
        .subscribe(([blog, pages]) => {
          this.urls = [...blog.data, ...pages.data];
          blog.meta.pagination.total += pages.data.length;
          this.pagination = blog.meta.pagination;
        }),
    );
  }

  public toggleSearch() {
    this.isShowSearchBox = !this.isShowSearchBox;
  }

  public showContent(index: number) {
    this.selectedIndex = index;
  }

  public hideContent() {
    this.selectedIndex = -1;
  }

  public toggleMenu() {
    this.isMenuShow = !this.isMenuShow;
  }

  public doCollapse(): void {
    this.collapse.emit();
  }

  public goToSearch(): void {
    this.router
      .navigate(['search'], {queryParams: {query: this.queryText}})
      .finally();
  }

  public get query(): AbstractControl {
    return this.form.controls['query'];
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
