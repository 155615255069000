import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-thank-popup',
  templateUrl: './thank-popup.component.html',
  styleUrls: ['./thank-popup.component.scss'],
})
export class ThankPopupComponent implements OnInit {
  public isShowPopUp: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  public ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe(queryParamMap => {
      this.isShowPopUp = queryParamMap.get('popup') === 'thank-you';
    });
  }

  public closePopUp(): void {
    this.router
      .navigate([], {
        queryParams: {
          popup: null,
        },
        queryParamsHandling: 'merge',
      })
      .finally();
  }
}
