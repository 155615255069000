import {
  ElementRef,
  Inject,
  Injectable,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map, retry} from 'rxjs/operators';

import {stringify} from 'qs';

import {environment} from 'src/environments/environment';
import {SearchRespList} from '../../interfaces/cms.interface';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(
    @Inject(PLATFORM_ID) public platformId: any,
    private httpClient: HttpClient,
  ) {}

  public getSearchResult(
    searchText: string,
    limit?: number,
  ): Observable<SearchRespList> {
    const endpoint = environment.cms + '/api/blogs?';
    const paramsJSON: Record<string, any> = {
      filters: {
        $or: [
          {
            title: {
              $containsi: searchText,
            },
          },
          {
            metaKeywords: {
              $containsi: searchText,
            },
          },
          {
            metaDescription: {
              $containsi: searchText,
            },
          },
        ],
      },
    };
    if (limit) {
      paramsJSON['pagination'] = {limit};
    }
    const paramTxt = stringify(paramsJSON, {
      encodeValuesOnly: true,
    });
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${environment.accessToken}`,
    };
    return this.httpClient.get<SearchRespList>(endpoint + paramTxt, {
      headers,
    });
  }

  public getLocalSearchResult(
    searchText: string,
    limit?: number,
  ): Observable<SearchRespList> {
    if (isPlatformServer(this.platformId)) {
      return of({
        data: [],
        meta: {pagination: {page: 0, pageSize: 0, pageCount: 0, total: 0}},
      });
    }
    const localApi = window.location.origin + '/assets/api';
    return this.httpClient.get<SearchRespList>(localApi + '/pages.json').pipe(
      retry(1),
      map(resp => {
        {
          resp.data = resp.data.filter(
            item =>
              item.attributes.metaTitle.includes(searchText) ||
              item.attributes.metaDescription.includes(searchText) ||
              item.attributes.metaKeywords.includes(searchText),
          );

          if (limit) {
            resp.data = resp.data.splice(0, limit);
          }
          return resp;
        }
      }),
    );
  }

  public getBlob(blobUrl: string): Observable<Blob> {
    return this.httpClient.get(blobUrl, {responseType: 'blob'});
  }

  public download(
    blob: Blob,
    fileName: string,
    ext: string,
    renderer: Renderer2,
    elementRef: ElementRef,
  ): void {
    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    const link = renderer.createElement('a');
    renderer.setAttribute(
      link,
      'download',
      fileName + '-' + +new Date().getTime().toString(16) + ext,
    );
    renderer.setAttribute(link, 'href', url);
    renderer.setAttribute(link, 'target', '_blank');
    renderer.appendChild(elementRef.nativeElement, link);
    link.click();
    renderer.removeChild(elementRef.nativeElement, link);

    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }
}
