<div class="overlay" id="thankYouPopup" *ngIf="isShowPopUp">
  <div class="popup_center">
    <div class="popup">
      <button id="thankYouHide" (click)="closePopUp()" class="close">
        &times;
      </button>
      <div class="modal-body">
        <div class="accept-cookies-popup net_center">
          <img
            src="/assets/images/vertel-logo-rbg.svg"
            alt="logo"
            loading="lazy"
          />
          <p>
            <strong> Thank you </strong>for submission. <br />
            We will get in touch with you shortly.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
