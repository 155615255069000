<header>
  <link
    rel="preload"
    as="image"
    href="/assets/images/building-connections.svg"
    type="image/svg+xml"
  />
  <div
    class="net_container hidden-sm hidden-s"
    id="desktopVertelConnectionImage"
  >
    <div class="pa-tb logo-header">
      <div class="logo">
        <a routerLink="/">
          <img
            src="/assets/images/vertel-logo-rbg.svg"
            alt="Vertel logo"
            loading="lazy"
            width="170"
            height="50"
          />
        </a>
      </div>
      <img
        src="/assets/images/building-connections.svg"
        alt="Building connections"
        loading="lazy"
        class="building-connections pa-tb-10"
        width="217"
        height="37"
      />
    </div>
  </div>

  <!-- Mobile -->
  <div class="header_area hidden-l hidden-m">
    <div class="main_header_area">
      <div class="net_container">
        <nav id="navigation1" class="navigation navigation-portrait">
          <div class="nav-header">
            <a class="nav-brand" href="/">
              <img
                src="/assets/images/vertel-logo-rbg.svg"
                alt="Vertel logo mobile"
                width="170"
                height="50"
              />
            </a>
            <span class="head_phone">
              <a href="tel:1300837835" class="bg_green clr_blue">
                <svg role="img" aria-label="Hello! Let's talk.">
                  <use xlink:href="assets/images/symbol.svg#icon_call"></use>
                </svg>
              </a>
            </span>
          </div>
          <div
            class="nav-toggle"
            (click)="toggleMenu()"
            id="toggleSection"
          ></div>
          <div
            class="nav-menus-wrapper nav-menus-wrapper-open"
            *ngIf="isMenuShow"
            style="transition-property: left"
            id="toggleShow"
          >
            <span class="nav-menus-wrapper-close-button" (click)="toggleMenu()"
              >✕</span
            >
            <ul class="nav-menu">
              <li class="left">
                <a (click)="toggleMenu()" routerLink="/services">Services</a>
                <div class="mega_menu_mobile">
                  <div>
                    <a
                      (click)="toggleMenu()"
                      routerLink="/services/network-solutions"
                      >Network Solutions</a
                    >
                    <ul>
                      <li>
                        <a
                          routerLink="/services/network-solutions/sd-wan"
                          (click)="toggleMenu()"
                          >SD-WAN</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/services/network-solutions/managed-wifi"
                          (click)="toggleMenu()"
                          >Business Wi-Fi Services</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/services/network-solutions/enterprise-ethernet"
                          (click)="toggleMenu()"
                          >Enterprise Ethernet</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/services/network-solutions/fixed-wireless"
                          (click)="toggleMenu()"
                          >Fixed Wireless</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/services/network-solutions/hybrid-networks"
                          (click)="toggleMenu()"
                          >Hybrid Networks</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/services/network-solutions/network-security"
                          (click)="toggleMenu()"
                          >Network Security</a
                        >
                      </li>
                    </ul>
                  </div>
                  <div>
                    <a
                      (click)="toggleMenu()"
                      routerLink="/services/critical-communications"
                      >Critical Communications</a
                    >
                    <ul>
                      <!-- <li>
                        <a
                          routerLink="/services/critical-communications/in-vehicle-network-solutions"
                          (click)="toggleMenu()"
                          >In-Vehicle Network Solutions</a
                        >
                      </li> -->
                      <li>
                        <a
                          routerLink="/services/critical-communications/mission-critical-mobility-solution"
                          (click)="toggleMenu()"
                          >Mission Critical Mobility Solution</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/services/critical-communications/mission-critical-services"
                          (click)="toggleMenu()"
                          >MCX - Mission Critical Services</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/services/critical-communications/poc"
                          (click)="toggleMenu()"
                          >Push to talk over Cellular
                        </a>
                      </li>
                      <li>
                        <a
                          routerLink="/services/critical-communications/virtual-training"
                          (click)="toggleMenu()"
                          >Virtual Training for Mission Critical Services</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/services/critical-communications/managed-radio-services"
                          (click)="toggleMenu()"
                          >Managed Radio Services</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/services/critical-communications/radio-dispatch-console"
                          (click)="toggleMenu()"
                          >Radio Dispatch Console</a
                        >
                      </li>
                    </ul>
                  </div>
                  <div>
                    <a
                      (click)="toggleMenu()"
                      routerLink="/services/cloud-services"
                      >Cloud Services</a
                    >
                    <ul>
                      <li>
                        <a
                          routerLink="/services/cloud-services/cloud-solutions"
                          (click)="toggleMenu()"
                          >Cloud Solution</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/services/cloud-services/baas"
                          (click)="toggleMenu()"
                          >Back up as a service
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <a (click)="toggleMenu()" routerLink="/services/saas"
                      >Software as a service</a
                    >
                    <ul>
                      <li>
                        <a
                          routerLink="/services/saas/wi-fi-analytics"
                          (click)="toggleMenu()"
                          >Wifi Analytics</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/services/saas/digital-wayfinding"
                          (click)="toggleMenu()"
                          >Digital Wayfinding</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/services/saas/ucaas"
                          (click)="toggleMenu()"
                          >Unified Communications as a service</a
                        >
                      </li>
                    </ul>
                  </div>
                  <div>
                    <a
                      (click)="toggleMenu()"
                      routerLink="/services/security-solutions"
                      >Security Solutions</a
                    >
                    <ul>
                      <!-- <li>
                        <a
                          routerLink="/services/security-solutions/network-security"
                          (click)="toggleMenu()"
                          >Network Security</a
                        >
                      </li> -->
                      <li>
                        <a
                          routerLink="/services/security-solutions/cctv-surveillance"
                          (click)="toggleMenu()"
                          >CCTV Services & Systems</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/services/security-solutions/surveillance-storage"
                          (click)="toggleMenu()"
                          >Surveillance Storage</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/services/security-solutions/surveillance-storage"
                          (click)="toggleMenu()"
                          >Access Control</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/services/security-solutions/license-plate-recognition"
                          (click)="toggleMenu()"
                          >License Plate Recognition</a
                        >
                      </li>
                    </ul>
                  </div>
                  <!-- <div>
                    <a
                      (click)="toggleMenu()"
                      routerLink="/services/mobile-radio-services"
                      >Mobile Radio Services</a
                    >
                    <ul>
                      
                      
                      <li>
                        <a
                          routerLink="/services/mobile-radio-services/site-facilities"
                          (click)="toggleMenu()"
                          >Site Facilities</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/services/mission-critical-ptt"
                          (click)="toggleMenu()"
                          >Mission Critical PTT</a
                        >
                      </li>
                    </ul>
                  </div> -->
                </div>
              </li>
              <li class="left">
                <a routerLink="/industries" (click)="toggleMenu()"
                  >Industries</a
                >
                <div class="mega_menu_mobile">
                  <div>
                    <!-- <h3>Industries</h3> -->
                    <ul>
                      <li>
                        <a
                          routerLink="/industries/government"
                          (click)="toggleMenu()"
                          >Government</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/industries/enterprise"
                          (click)="toggleMenu()"
                          >Enterprise</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="industries/utilities"
                          (click)="toggleMenu()"
                          >Utilities</a
                        >
                      </li>
                      <li>
                        <a routerLink="/msp-healthcare" (click)="toggleMenu()"
                          >Healthcare providers</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/industries/holidayparks"
                          (click)="toggleMenu()"
                          >Holiday Parks</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/industries/renewables"
                          (click)="toggleMenu()"
                          >Renewables</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/industries/education"
                          (click)="toggleMenu()"
                          >Education</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li class="left">
                <a routerLink="/partner" (click)="toggleMenu()">Partner</a>
              </li>
              <li class="left">
                <a href="/resources" (click)="toggleMenu()">Resources</a>
              </li>
              <li class="left">
                <a routerLink="/about-us" (click)="toggleMenu()">About us </a>
                <div class="mega_menu_mobile">
                  <div>
                    <ul>
                      <li>
                        <a routerLink="/about-us/careers" (click)="toggleMenu()"
                          >Career</a
                        >
                      </li>
                      <li>
                        <a
                          routerLink="/about-us/corporate-social-responsibility"
                          (click)="toggleMenu()"
                        >
                          CSR
                        </a>
                      </li>
                      <li>
                        <a
                          routerLink="/about-us/our-expert-ict-msp-team"
                          (click)="toggleMenu()"
                        >
                          Our Team
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li class="left">
                <a routerLink="/contact-us" (click)="toggleMenu()"
                  >Contact us</a
                >
              </li>
              <li class="left">
                <a
                  href="https://portal.vertel.com.au"
                  target="_blank"
                  rel="noopener"
                  >Customer Portal</a
                >
              </li>
            </ul>
          </div>
          <div
            (click)="toggleMenu()"
            *ngIf="isMenuShow"
            class="nav-overlay-panel"
            style="background-color: rgba(0, 0, 0, 0.5); display: block"
          ></div>
        </nav>
      </div>
    </div>
  </div>

  <!-- Desktop -->
  <div
    id="desktopSticky"
    class="desktop_menu net_full_width hidden-s hidden-sm"
    [ngClass]="{
      sticky: isSticky,
      nested_page_bg: isExpanded
    }"
  >
    <div class="net_container">
      <div class="row">
        <div class="net l12 m12 sm12 s12">
          <div class="menu_row">
            <div class="net l8 m8 sm8 s12 padding0">
              <nav class="net_full_width menu_row_nav">
                <ul class="net_full_width menu-link-ul" id="navLink">
                  <li
                    *ngFor="let menu of menus; let i = index"
                    class="left"
                    [id]="menu.title"
                  >
                    <div
                      (mouseover)="showContent(i)"
                      (mouseleave)="hideContent()"
                    >
                      <a
                        (click)="hideContent()"
                        class="menu-link tab dropdown"
                        *ngIf="menu.children"
                        [routerLink]="menu.link"
                      >
                        {{ menu.title }}
                        <span id="chevronArrowDown"></span>
                      </a>
                      <a
                        (click)="hideContent()"
                        class="menu-link tab dropdown"
                        [routerLink]="menu.link"
                        *ngIf="!menu.children"
                      >
                        {{ menu.title }}
                      </a>

                      <div
                        class="bg_inner_menu position_with_header"
                        *ngIf="selectedIndex === i && menu.children"
                      >
                        <div class="mega_menu">
                          <ng-container *ngFor="let children of menu.children">
                            <div class="mega_menu_item">
                              <a
                                class="category"
                                (click)="hideContent()"
                                [routerLink]="children.link"
                                >{{ children.category }}</a
                              >
                              <ul>
                                <li *ngFor="let subMenu of children.subMenus">
                                  <a
                                    (click)="hideContent()"
                                    [routerLink]="subMenu.link"
                                    >{{ subMenu.title }}</a
                                  >
                                </li>
                              </ul>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="net l4 m4 sm4 s12 header-search-panel" id="searchPanel">
              <div class="head_search pa-tb-10">
                <div
                  class="bg_green clr_blue"
                  id="searchToggle"
                  (click)="toggleSearch()"
                >
                  <svg>
                    <use
                      href="assets/images/symbol.svg#magnifying_icon"
                      alt="search"
                    ></use>
                  </svg>
                </div>
              </div>
              <div class="customer_portal">
                <a
                  href="https://portal.vertel.com.au"
                  target="_blank"
                  class="bg_green clr_blue"
                  rel="noopener"
                >
                  Customer Portal
                </a>
              </div>
              <div class="head_phone pa-tb-10">
                <a href="tel:1300837835" class="bg_green clr_blue">
                  <svg role="img" aria-label="call">
                    <use href="assets/images/symbol.svg#icon_call"></use>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          appClickOutside
          (outsideClick)="toggleSearch()"
          class="search_bar ui-helper-hidden-accessible"
          *ngIf="isShowSearchBox"
        >
          <form [formGroup]="form">
            <input
              type="text"
              placeholder="Search ..."
              id="searchInputHeader"
              autocomplete="off"
              formControlName="query"
              appAutofocus
              (keyup.enter)="goToSearch()"
            />
          </form>
          <button class="close_search" (click)="toggleSearch()">×</button>
          <div class="net l12 m12 s12">
            <ul
              class="auto_suggestion_search"
              *ngIf="urls.length !== 0"
              (click)="toggleSearch()"
            >
              <li class="item" *ngFor="let url of urls">
                <!-- for local -->
                <a
                  [routerLink]="url.attributes.url"
                  *ngIf="
                    url.attributes.url && url.attributes.title === undefined
                  "
                >
                  {{ url.attributes.metaTitle }}
                </a>
                <!-- pdf -->
                <a
                  [href]="
                    cdnPrefix +
                    '/uploads/' +
                    url.attributes.doc?.data?.attributes?.url
                  "
                  *ngIf="url.attributes.doc?.data?.attributes?.url"
                >
                  {{ url.attributes.title }}
                </a>
                <!-- blogs -->
                <a
                  [href]="'resources/' + url.attributes.url"
                  *ngIf="url.attributes.url && url.attributes.title"
                >
                  {{ url.attributes.title }}
                </a>
                <!-- youtube -->
                <a
                  [href]="url.attributes.youtubeUrl"
                  target="_blank"
                  *ngIf="url.attributes.youtubeUrl"
                >
                  {{ url.attributes.title }}
                </a>
              </li>
              <li class="last" *ngIf="pagination && pagination.total > 1">
                <a [routerLink]="['search']" [queryParams]="{query: queryText}">
                  View all {{ pagination.total }} results for
                  <strong>{{ queryText }}</strong>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
