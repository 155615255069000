import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ServiceWorkerModule} from '@angular/service-worker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {QuicklinkModule} from 'ngx-quicklink';

import {AppRoutingModule} from './app-routing.module';
import {environment} from '../environments/environment';

import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {CookiesPopupComponent} from './components/cookies-popup/cookies-popup.component';
import {ThankPopupComponent} from './components/thank-popup/thank-popup.component';
import {StandardComponent} from './layouts/standard/standard.component';
import {FullScreenComponent} from './layouts/full-screen/full-screen.component';
import {AutofocusDirective} from './directives/autofocus/autofocus.directive';
import {ClickOutsideDirective} from './directives/click-outside/click-outside.directive';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {StandardLayoutComponent} from './layouts/standard-layout/standard-layout.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    CookiesPopupComponent,
    ThankPopupComponent,
    StandardComponent,
    FullScreenComponent,
    AutofocusDirective,
    ClickOutsideDirective,
    AuthLayoutComponent,
    StandardLayoutComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production || environment.staging,
      // Register the ServiceWorker as soon as the config is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ReactiveFormsModule,
    HttpClientModule,
    QuicklinkModule,
    FormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
