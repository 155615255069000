import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {QuicklinkStrategy} from 'ngx-quicklink';

import {FullScreenComponent} from './layouts/full-screen/full-screen.component';
import {StandardComponent} from './layouts/standard/standard.component';
import {StandardLayoutComponent} from './layouts/standard-layout/standard-layout.component';
import {AuthorizedGuard} from './guards/authorized/authorized.guard';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {UnauthorizedGuard} from './guards/unauthorized/unauthorized.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: StandardComponent,
    loadChildren: () =>
      import('./pages/home/home.module').then(m => m.HomeModule),
  },
  {
    // For Standard Layout
    path: '',
    component: StandardComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'about-us',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/about/about.module').then(m => m.AboutModule),
          },
          {
            path: 'corporate-social-responsibility',
            loadChildren: () =>
              import('./pages/csr/csr.module').then(m => m.CsrModule),
          },
          {
            path: 'careers',
            loadChildren: () =>
              import('./pages/career/career.module').then(m => m.CareerModule),
          },
          {
            path: 'our-expert-ict-msp-team',
            loadChildren: () =>
              import('./pages/our-team/our-team.module').then(
                m => m.OurTeamModule,
              ),
          },
        ],
      },
      {
        path: 'contact-us',
        loadChildren: () =>
          import('./pages/contact/contact.module').then(m => m.ContactModule),
      },
      {
        path: 'partner',
        loadChildren: () =>
          import('./pages/partner/partner.module').then(m => m.PartnerModule),
      },
      {
        path: 'industries',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/industries/industries.module').then(
                m => m.IndustriesModule,
              ),
          },
          {
            path: 'government',
            loadChildren: () =>
              import('./pages/government/government.module').then(
                m => m.GovernmentModule,
              ),
          },
          {
            path: 'enterprise',
            loadChildren: () =>
              import('./pages/enterprise/enterprise.module').then(
                m => m.EnterpriseModule,
              ),
          },
          {
            path: 'utilities',
            loadChildren: () =>
              import('./pages/utilities/utilities.module').then(
                m => m.UtilitiesModule,
              ),
          },
          {
            path: 'holidayparks',
            loadChildren: () =>
              import('./pages/holiday-parks/holiday-parks.module').then(
                m => m.HolidayParksModule,
              ),
          },
          {
            path: 'holidayparks',
            loadChildren: () =>
              import('./pages/holiday-parks/holiday-parks.module').then(
                m => m.HolidayParksModule,
              ),
          },
          {
            path: 'education',
            loadChildren: () =>
              import('./pages/education/education.module').then(
                m => m.EducationModule,
              ),
          },
          {
            path: 'renewables',
            loadChildren: () =>
              import('./pages/renewables/renewables.module').then(
                m => m.RenewablesModule,
              ),
          },
        ],
      },
      {
        path: 'services',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/services/services.module').then(
                m => m.ServicesModule,
              ),
          },
          {
            path: 'mission-critical-ptt',
            loadChildren: () =>
              import(
                './pages/mission-critical-ptt/mission-critical-ptt.module'
              ).then(m => m.MissionCriticalPttModule),
          },
          {
            path: 'saas',
            children: [
              {
                path: '',
                loadChildren: () =>
                  import('./pages/saas/saas.module').then(m => m.SaasModule),
              },
              {
                path: 'ucaas',
                loadChildren: () =>
                  import('./pages/ucaas-8x8/ucaas-8x8.module').then(
                    m => m.Ucaas8x8Module,
                  ),
              },
              {
                path: 'digital-wayfinding',
                loadChildren: () =>
                  import(
                    './pages/digital-wayfinding/digital-wayfinding.module'
                  ).then(m => m.DigitalWayfindingModule),
              },
              {
                path: 'wi-fi-analytics',
                loadChildren: () =>
                  import('./pages/wifi-analytics/wifi-analytics.module').then(
                    m => m.WifiAnalyticsModule,
                  ),
              },
            ],
          },
          {
            path: 'network-solutions',
            children: [
              {
                path: '',
                loadChildren: () =>
                  import(
                    './pages/network-solutions/network-solutions.module'
                  ).then(m => m.NetworkSolutionsModule),
              },
              {
                path: 'sd-wan',
                loadChildren: () =>
                  import('./pages/sd-wan/sd-wan.module').then(
                    m => m.SdWanModule,
                  ),
              },
              {
                path: 'managed-wifi',
                loadChildren: () =>
                  import('./pages/managed-wifi/managed-wifi.module').then(
                    m => m.ManagedWifiModule,
                  ),
              },
              {
                path: 'enterprise-ethernet',
                loadChildren: () =>
                  import('./pages/ethernet/ethernet.module').then(
                    m => m.EthernetModule,
                  ),
              },
              {
                path: 'network-security',
                loadChildren: () =>
                  import(
                    './pages/network-security/network-security.module'
                  ).then(m => m.NetworkSecurityModule),
              },
              {
                path: 'fixed-wireless',
                loadChildren: () =>
                  import('./pages/fixed-wireless/fixed-wireless.module').then(
                    m => m.FixedWirelessModule,
                  ),
              },
              {
                path: 'hybrid-networks',
                loadChildren: () =>
                  import('./pages/hybrid-networks/hybrid-networks.module').then(
                    m => m.HybridNetworksModule,
                  ),
              },
            ],
          },
          {
            path: 'mobile-radio-services',
            children: [
              {
                path: '',
                loadChildren: () =>
                  import(
                    './pages/mobile-radio-services/mobile-radio-services.module'
                  ).then(m => m.MobileRadioServicesModule),
              },

              {
                path: 'site-facilities',
                loadChildren: () =>
                  import('./pages/site-facilities/site-facilities.module').then(
                    m => m.SiteFacilitiesModule,
                  ),
              },
            ],
          },
          {
            path: 'critical-communications',
            children: [
              {
                path: '',
                loadChildren: () =>
                  import(
                    './pages/critical-communications/critical-communications.module'
                  ).then(m => m.CriticalCommunicationsModule),
              },
              {
                path: 'mission-critical-services',
                loadChildren: () =>
                  import(
                    './pages/mission-critical-services/mission-critical-services.module'
                  ).then(m => m.MissionCriticalServicesModule),
              },
              {
                path: 'in-vehicle-network-solutions',
                loadChildren: () =>
                  import(
                    './pages/in-vehicle-network-solutions/in-vehicle-network-solutions.module'
                  ).then(m => m.InVehicleNetworkSolutionsModule),
              },
              {
                path: 'radio-dispatch-console',
                loadChildren: () =>
                  import(
                    './pages/radio-dispatch-console/radio-dispatch-console.module'
                  ).then(m => m.RadioDispatchConsoleModule),
              },
              {
                path: 'virtual-training',
                loadChildren: () =>
                  import('./pages/beaconsim/beaconsim.module').then(
                    m => m.BeaconsimModule,
                  ),
              },
              {
                path: 'mission-critical-mobility-solution',
                loadChildren: () =>
                  import(
                    './pages/mission-critical-mobility-solution/mission-critical-mobility-solution.module'
                  ).then(m => m.MissionCriticalMobilitySolutionModule),
              },
              {
                path: 'poc',
                loadChildren: () =>
                  import('./pages/poc/poc.module').then(m => m.PocModule),
              },
              {
                path: 'managed-radio-services',
                loadChildren: () =>
                  import(
                    './pages/managed-radio-services/managed-radio-services.module'
                  ).then(m => m.ManagedRadioServicesModule),
              },
            ],
          },
          {
            path: 'cloud-services',
            children: [
              {
                path: '',
                loadChildren: () =>
                  import('./pages/cloud-services/cloud-services.module').then(
                    m => m.CloudServicesModule,
                  ),
              },
              {
                path: 'baas',
                loadChildren: () =>
                  import('./pages/baas/baas.module').then(m => m.BaasModule),
              },
              {
                path: 'cloud-solutions',
                loadChildren: () =>
                  import('./pages/cloud-solutions/cloud-solutions.module').then(
                    m => m.CloudSolutionsModule,
                  ),
              },
            ],
          },
          {
            path: 'security-solutions',
            children: [
              {
                path: '',
                loadChildren: () =>
                  import(
                    './pages/security-solutions/security-solutions.module'
                  ).then(m => m.SecuritySolutionsModule),
              },

              {
                path: 'access-control',
                loadChildren: () =>
                  import('./pages/access-control/access-control.module').then(
                    m => m.AccessControlModule,
                  ),
              },
              {
                path: 'cctv-surveillance',
                loadChildren: () =>
                  import(
                    './pages/cctv-surveillance/cctv-surveillance.module'
                  ).then(m => m.CctvSurveillanceModule),
              },
              {
                path: 'surveillance-storage',
                loadChildren: () =>
                  import(
                    './pages/surveillance-storage/surveillance-storage.module'
                  ).then(m => m.SurveillanceStorageModule),
              },
              {
                path: 'license-plate-recognition',
                loadChildren: () =>
                  import(
                    './pages/automatic-license-plate-recognition/automatic-license-plate-recognition.module'
                  ).then(m => m.AutomaticLicensePlateRecognitionModule),
              },
            ],
          },
        ],
      },
      {
        path: 'resources',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/blog/blog.module').then(m => m.BlogModule),
          },
          // {
          //   path: 'vertel-iconwater-complete-major-comms-network-upgrade',
          //   loadChildren: () =>
          //     import('./pages/news-release/news-release.module').then(
          //       m => m.NewsReleaseModule,
          //     ),
          // },
          {
            path: ':slug',
            loadChildren: () =>
              import('./pages/blog-detail/blog-detail.module').then(
                m => m.BlogDetailModule,
              ),
            // data: {
            //   preload: false,
            // },
          },
        ],
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./pages/search-details/search-details.module').then(
            m => m.SearchDetailsModule,
          ),
        data: {
          preload: false,
        },
      },
      {
        path: 'terms-of-use',
        loadChildren: () =>
          import('./pages/terms-of-use/terms-of-use.module').then(
            m => m.TermsOfUseModule,
          ),
        data: {
          preload: false,
        },
      },
      {
        path: 'fair-use-policy',
        loadChildren: () =>
          import('./pages/fair-use-policy/fair-use-policy.module').then(
            m => m.FairUsePolicyModule,
          ),
        data: {
          preload: false,
        },
      },
      {
        path: 'privacy-policy',
        loadChildren: () =>
          import('./pages/privacy-policy/privacy-policy.module').then(
            m => m.PrivacyPolicyModule,
          ),
        data: {
          preload: false,
        },
      },
      {
        path: 'healthcare-wayfinding',
        loadChildren: () =>
          import(
            './pages/healthcare-wayfinding/healthcare-wayfinding.module'
          ).then(m => m.HealthcareWayfindingModule),
        data: {
          preload: false,
        },
      },
      {
        path: 'msp-healthcare',
        loadChildren: () =>
          import('./pages/msp-healthcare/msp-healthcare.module').then(
            m => m.MspHealthcareModule,
          ),
        data: {
          preload: false,
        },
      },
      {
        path: 'bus-campaign',
        loadChildren: () =>
          import('./pages/bus-campaign/bus-campaign.module').then(
            m => m.BusCampaignModule,
          ),
        data: {
          preload: false,
        },
      },
      {
        path: 'aged-care-digital-wayfinding',
        loadChildren: () =>
          import('./pages/aged-care/aged-care.module').then(
            m => m.AgedCareModule,
          ),
        data: {
          preload: false,
        },
      },
      {
        path: 'big4conference',
        loadChildren: () =>
          import('./pages/big4conference/big4conference.module').then(
            m => m.Big4conferenceModule,
          ),
        data: {
          preload: false,
        },
      },
      {
        path: 'gday22',
        loadChildren: () =>
          import(
            './pages/holiday-park-national-conference/holiday-park-national-conference.module'
          ).then(m => m.HolidayParkNationalConferenceModule),
        data: {
          preload: false,
        },
      },
      {
        path: 'Caravan23',
        loadChildren: () =>
          import('./pages/caravan-23/caravan-23.module').then(
            m => m.Caravan23Module,
          ),
        data: {
          preload: false,
        },
      },
      {
        path: 'aruba-smartrouter',
        loadChildren: () =>
          import(
            './pages/renewables-construction/renewables-construction.module'
          ).then(m => m.RenewablesConstructionModule),
        data: {
          preload: false,
        },
      },
    ],
  },
  // For FullScreen Layout
  {
    path: '',
    component: FullScreenComponent,
    children: [],
  },
  // For without layout
  {
    path: 'under-construction',
    loadChildren: () =>
      import('./pages/under-construction/under-construction.module').then(
        m => m.UnderConstructionModule,
      ),
  },
  {
    path: 'resources-manage',
    component: StandardLayoutComponent,
    canActivate: [AuthorizedGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/resources-manage/resources-manage.module').then(
            r => r.ResourcesManageModule,
          ),
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    canActivate: [UnauthorizedGuard],
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('./pages/login/login.module').then(l => l.LoginModule),
      },
    ],
  },
  {
    path: 'not-found',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then(m => m.NotFoundModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollOffset: [0, 25],
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      preloadingStrategy: QuicklinkStrategy,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
