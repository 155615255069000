import {Component, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs';

import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private dom: Document,
    private router: Router,
  ) {}

  ngOnInit() {
    // add canonical url
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe({
        next: event => {
          const head = this.dom.getElementsByTagName('head')[0];
          const element = this.dom.createElement('link') as HTMLLinkElement;
          element.setAttribute('rel', 'canonical');
          element.setAttribute(
            'href',
            environment.host + (event as NavigationEnd).url,
          );
          head.appendChild(element);
        },
      });
  }
}
