// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  staging: false,
  // // accessToken:
  // //   'a318a9e0e853273336b3320c7ebab7375f5bebc9b1108d409bbacc8d124b50007ade29a4899a26c5c3eef9497733f3255c0aeb3652ae856bb7813a59606fc0ff790db56711d89f7795e39a3e3782e0b9b69924981db2f6b908eb98006b394f476bdf8f504b5327f06629206b5f03644c885f70ab27b7ee8ccb0430a5db76a0b3',
  // // cms: 'http://192.168.16.50:1337', //this is using for use production
  accessToken: '',
  cms: 'https://cms.vertel.com.au',
  host: 'http://localhost:4200',
  apiUrl: 'https://vertel.com.au/api',
  s3: 'https://vertwebbucket.s3.ap-southeast-2.amazonaws.com/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
