<footer>
  <div class="net_container">
    <div class="footer-content">
      <div class="row">
        <div class="net l3 m2 sm3 s12" id="vertelImage">
          <div class="footer-logo">
            <a routerLink="/"
              ><img
                width="135"
                height="40"
                src="/assets/images/vertel-logo-rbg-white.svg"
                alt="Vertel Logo"
                loading="lazy"
            /></a>
          </div>
        </div>
        <div class="net l6 m7 sm9 s12" id="termsPrivacyFair">
          <ul class="footer-link">
            <li><a routerLink="/terms-of-use">Terms of use</a></li>
            <li><a routerLink="/privacy-policy">Privacy policy</a></li>
            <li><a routerLink="/fair-use-policy">Fair use policy</a></li>
            <li>© Vertel 2024</li>
          </ul>
        </div>
        <div class="net l3 m3 sm12 s12">
          <ul class="footer-social" id="social">
            <li>
              <a routerLink="/contact-us" class="footer-contact-btn"
                >CONTACT US</a
              >
            </li>
            <li>
              <a
                href="https://www.youtube.com/user/VerticalTelecoms"
                target="_blank"
                rel="noopener"
              >
                <img
                  src="assets/images/youtube.svg"
                  alt="You Tube"
                  loading="lazy"
                  height="40px"
                  width="40px"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/vertel"
                target="_blank"
                rel="noopener"
              >
                <svg role="img" aria-label="linkedin">
                  <use href="assets/images/symbol.svg#icon-linkedin"></use>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
