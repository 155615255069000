import {isPlatformBrowser} from '@angular/common';
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

import {ConfigService} from 'src/app/services/config/config.service';

@Component({
  selector: 'app-cookies-popup',
  templateUrl: './cookies-popup.component.html',
  styleUrls: ['./cookies-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesPopupComponent implements OnInit {
  isShowCookiesPopUp: boolean = false;

  constructor(private configService: ConfigService) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.configService.platformId)) {
      this.isShowCookiesPopUp =
        localStorage.getItem('isCookiesAccepted') !== 'true';
    }
  }

  acceptCookies(): void {
    this.isShowCookiesPopUp = false;
    if (isPlatformBrowser(this.configService.platformId)) {
      localStorage.setItem('isCookiesAccepted', 'true');
    }
  }
}
